import { defineMessages } from 'react-intl';

export const scope = 'components.ui.Paginate';

export default defineMessages({
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next'
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: 'Previous'
  },
  break: {
    id: `${scope}.break`,
    defaultMessage: '...'
  }
});
