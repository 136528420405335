import React from 'react';
import PropTypes from 'prop-types';
import Paginate from '@rentivo/gatsby-core/src/components/ui/Paginate/component';

Paginate.defaultProps = {
  className: 'pagination-wrap',
  size: 'base'
};

Paginate.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'base', 'lg'])
};

export default Paginate;
