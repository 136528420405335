import React from 'react';
import PaginationBoxView from '@rentivo/gatsby-core/src/components/ui/PaginationBoxView';
import { StyledPaginate } from '@rentivo/gatsby-core/src/components/ui/Paginate/styles';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/ui/Paginate/messages';

const PaginateComponent = ({className, size, style, ...rest}) => {
  return (
    <StyledPaginate className={className} attrs={{size}}>
      <PaginationBoxView
        {...rest}
        previousLabel={<FormattedMessage {...messages.previous}/>}
        nextLabel={<FormattedMessage {...messages.next}/>}
        activeClassName={`active`}
        containerClassName={`pagination`}
      />
    </StyledPaginate>
  );
};

export default PaginateComponent;
